<script>
    // import Formulario from './Mine/Formulario.vue'
    
    export default {
        components: {
            // Formulario
        }
    }
</script>

<style scoped>
</style>